<template>
  <div>
    <b-row>
      <b-col cols="8">
        <h2>
          Données de référentiel (locales)
          <info-helper>
            <p>Un certain nombre de données de référentiel sont nécessaires au bon fonctionnement de l'application (notation) y-compris en mode "déconnecté" (absence de réseau, mode avion...).</p>
            <p>Il y a donc un mécanisme de recopie dans une base "locale" (sur votre appareil) de ces données de référentiel.</p>
            <p>La vue globale présentée ici permet d'avoir une cartographie de l'ensemble des données de référentiel telles que présententes dans votre base locale, indépendamment des éventuelles modifications effectuées sur le serveur. Il s'agit donc d'une vue désynchronisée de ces données.</p>
          </info-helper>
        </h2>
      </b-col>
      <b-col cols="4">
      </b-col>
    </b-row>

    <collapse-panel title="Règlements" :visible="false" @expand="expand_regulation" ref="regulationsPanel">
      <b-table-simple responsive>
        <b-thead head-variant="dark">
          <b-tr>
            <b-th sticky-column>Code</b-th>
            <b-th sticky-column>Fédéral</b-th>
            <!--<b-th>Nom court</b-th>-->
            <!--<b-th>Nom</b-th>-->
            <!--<b-th>Nom long</b-th>-->
            <b-th>Nom | Nom <i>court</i> | Nom <i>long</i></b-th>
            <b-th>CV acro</b-th>
            <b-th>Indoor</b-th>
            <b-th>Freestyle</b-th>
            <b-th>Monofil</b-th>
            <b-th>Archivé ?</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="regulation in regulations" :key="regulation.code" :class="{ 'archived': regulation.isArchived, 'selected': regulation.code == selectedRegulation}">
            <b-th sticky-column>{{ regulation.code }}</b-th>
            <b-th sticky-column><b-checkbox :checked="regulation.isFederal" /></b-th>
            <!--<b-td>{{ regulation.shortname}}</b-td>
            <b-td><b>{{ regulation.name }}</b></b-td>
            <b-td>{{ regulation.longname }}</b-td>-->
            <b-td>
              <b>{{ regulation.name }}</b>
              <div v-show="regulation.shortname != regulation.name">court : <i>{{ regulation.shortname }}</i></div>
              <div v-show="regulation.longName != regulation.name">long : <i>{{ regulation.longName }}</i></div>
            </b-td>
            <b-td><b-checkbox :checked="regulation.isCva" /></b-td>
            <b-td><b-checkbox :checked="regulation.isIndoor" /></b-td>
            <b-td><b-checkbox :checked="regulation.isFreestyle" /></b-td>
            <b-td><b-checkbox :checked="regulation.isMonofil" /></b-td>
            <b-td><b-checkbox :checked="regulation.isArchived" /></b-td>
          </b-tr>
        </b-tbody>
        <b-tfoot>
          <b-tr>
            <b-td colspan="8" variant="secondary">
              Nombre de lignes : <b>{{ regulations.length }}</b>
            </b-td>
          </b-tr>
        </b-tfoot>
      </b-table-simple>
    </collapse-panel>

    <collapse-panel title="Types de compétition" :visible="false" @expand="expand_competitionType" ref="competitionsTypesPanel">
      <b-table-simple responsive>
        <b-thead head-variant="dark">
          <b-tr>
            <b-th sticky-column>Code</b-th>
            <b-th sticky-column>Fédéral ?</b-th>
            <!--<b-th>Nom court</b-th>-->
            <!--<b-th>Nom</b-th>-->
            <!--<b-th>Nom long</b-th>-->
            <b-th>Nom | Nom <i>court</i> | Nom <i>long</i></b-th>
            <b-th>Règlement</b-th>
            <b-th>Archivé ?</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="competitionType in competitionsTypes" :key="competitionType.code" :class="{ 'archived': competitionType.isArchived, 'selected': competitionType.code == selectedCompetitionType}">
            <b-th sticky-column>{{ competitionType.code }}</b-th>
            <b-th sticky-column><b-checkbox :checked="competitionType.isFederal" /></b-th>
            <!--<b-td>{{ competitionType.shortname}}</b-td>
            <b-td><b>{{ competitionType.name }}</b></b-td>
            <b-td>{{ competitionType.longname }}</b-td>-->
            <b-td>
              <b>{{ competitionType.name }}</b>
              <div v-show="competitionType.shortname != competitionType.name">court : <i>{{ competitionType.shortname }}</i></div>
              <div v-show="competitionType.longName != competitionType.name">long : <i>{{ competitionType.longName }}</i></div>
            </b-td>
            <b-td><a class="refToREF" @click="select_regulation(competitionType.regulation)">{{ competitionType.REGULATION != null ? competitionType.REGULATION.shortname : competitionType.regulation }}</a></b-td>
            <b-td><b-checkbox :checked="competitionType.isArchived" /></b-td>
          </b-tr>
        </b-tbody>
        <b-tfoot>
          <b-tr>
            <b-td colspan="8" variant="secondary">
              Nombre de lignes : <b>{{ competitionsTypes.length }}</b>
            </b-td>
          </b-tr>
        </b-tfoot>
      </b-table-simple>
    </collapse-panel>

    <collapse-panel title="Echelons" :visible="false" @expand="expand_scope" ref="scopesPanel">
      <b-table-simple responsive>
        <b-thead head-variant="dark">
          <b-tr>
            <b-th sticky-column>Code</b-th>
            <!--<b-th>Nom court</b-th>-->
            <!--<b-th>Nom</b-th>-->
            <!--<b-th>Nom long</b-th>-->
            <b-th>Nom | Nom <i>court</i> | Nom <i>long</i></b-th>
            <b-th>Archivé ?</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="scope in scopes" :key="scope.code" :class="{ 'archived': scope.isArchived, 'selected': scope.code == selectedScope}">
            <b-th sticky-column>{{ scope.code }}</b-th>
            <!--<b-td>{{ competitionType.shortname}}</b-td>
            <b-td><b>{{ competitionType.name }}</b></b-td>
            <b-td>{{ competitionType.longname }}</b-td>-->
            <b-td>
              <b>{{ scope.name }}</b>
              <div v-show="scope.shortname != scope.name">court : <i>{{ scope.shortname }}</i></div>
              <div v-show="scope.longName != scope.name">long : <i>{{ scope.longname }}</i></div>
            </b-td>
            <b-td><b-checkbox :checked="scope.isArchived" /></b-td>
          </b-tr>
        </b-tbody>
        <b-tfoot>
          <b-tr>
            <b-td colspan="8" variant="secondary">
              Nombre de lignes : <b>{{ scopes.length }}</b>
            </b-td>
          </b-tr>
        </b-tfoot>
      </b-table-simple>
    </collapse-panel>

    <collapse-panel title="Echelons possibles par types de compétition" :visible="false" @expand="expand_scopeByCompetitionType" ref="scopesByCompetitionTypePanel">
      <b-table-simple responsive>
        <b-thead head-variant="dark">
          <b-tr>
            <b-th>Echelon</b-th>
            <b-th>Type de compétition</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="scopeByCompetitionType in scopesByCompetitionsTypes" :key="scopeByCompetitionType.scope + '-' + scopeByCompetitionType.competition_type" :class="{  'selected': scopeByCompetitionType.scope == selectedCompetitionType && scopeByCompetitionType.competition_type == selectedCompetitionType}">
            <b-td><a class="refToREF" @click="select_scope(scopeByCompetitionType.scope)">{{ scopeByCompetitionType.SCOPE != null ? scopeByCompetitionType.SCOPE.name : scopeByCompetitionType.scope }}</a></b-td>
            <b-td><a class="refToREF" @click="select_competitionType(scopeByCompetitionType.competition_type)">{{ scopeByCompetitionType.COMPETITION_TYPE != null ? scopeByCompetitionType.COMPETITION_TYPE.name : scopeByCompetitionType.competion_type }}</a></b-td>
          </b-tr>
        </b-tbody>
        <b-tfoot>
          <b-tr>
            <b-td colspan="8" variant="secondary">
              Nombre de lignes : <b>{{ scopesByCompetitionsTypes.length }}</b>
            </b-td>
          </b-tr>
        </b-tfoot>
      </b-table-simple>
    </collapse-panel>
    <collapse-panel title="Niveaux">
    </collapse-panel>
    <collapse-panel title="Catégories">
    </collapse-panel>
    <collapse-panel title="Figures de précision">
    </collapse-panel>
    <collapse-panel title="Modes de calcule de résultat final"></collapse-panel>
    <collapse-panel title="Saisons">
    </collapse-panel>
  </div>
</template>

<script>
import { REF_REGULATION, REF_COMPETITION_TYPE, REF_SCOPE, REF_COMPETITION_TYPE_SCOPE } from "@/LocalDB";
import InfoHelper from '@/components/InfoHelper.vue';
import CollapsePanel from '@/components/Panels/CollapsePanel';

export default {
  components: { InfoHelper, CollapsePanel, },
  data(){
    return {
      regulations:[], selectedRegulation: '',
      competitionsTypes:[], selectedCompetitionType: '',
      scopes:[], selectedScope: '',
      scopesByCompetitionsTypes: [],
    }
  },
  methods:{
    expand_regulation(){
      this.regulations = REF_REGULATION.query().get();
    },
    select_regulation(regulation)
    {
      this.regulations = [];
      this.selectedRegulation = regulation;
      this.expand_regulation();
      this.$refs.regulationsPanel.expand();
    },
    expand_competitionType(){
      this.competitionsTypes = REF_COMPETITION_TYPE.query().get();
    },
    select_competitionType(competitionType){
      this.competitionsTypes = [];
      this.selectedCompetitionType = competitionType;
      this.expand_competitionType();
      this.$refs.competitionstypesPanel.expand();
    },
    expand_scope(){
      this.scopes = REF_SCOPE.query().get();
    },
    select_scope(scope){
      this.scopes = [];
      this.selectedScope = scope;
      this.expand_scope();
      this.$refs.scopesPanel.expand();
    },
    expand_scopeByCompetitionType(){
      this.scopesByCompetitionsTypes = REF_COMPETITION_TYPE_SCOPE.query().get();
    }
  },
  mounted(){
    this.$hideLoader();
  },
}
</script>

<style scoped>
  tr.selected { background-color: var(--ffvlOrangeLight); }
  tr.selected th { background-color: var(--ffvlOrangeLight); }
</style>

